<template>
  <div>
    <p>
      {{ itemsInCart }}
    </p>
    <p>
      Subtotal: {{ subtotal | toCurrency }}
    </p>
    <p class="button clear" v-if="!confirmRemove" @click="toggleRemove">
      Remove all
    </p>
    <div v-if="confirmRemove">
      <p class="button clear no-pointer">
        Are you sure?
      </p>
      <button class="button clear success" @click="toggleRemove(); removeAll();">Yes</button>
      <button class="button clear alert" @click="toggleRemove">No</button>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/scss/settings";
.button {
  &.no-pointer {
    cursor: default;

    &.clear{
      &:hover, &:focus {
        color: map-get($button-palette, primary);
      }
    }
  }
}
</style>

<script>
import _ from "lodash";
import { UPDATE_CART_ITEM } from "@/constants/graphql/checkout/cart";

export default {
  name: 'Summary',
  data() {
    return {
      confirmRemove: false
    }
  },
  computed: {
    itemsInCart: function() {
      let overallQty = this.$cart.cart.total_quantity;

      let output = overallQty + " item";
      if (overallQty > 1) output += "s";
      output += " in basket";

      return output;
    },
    subtotal: function() {
      if (_.has(this.$cart.cart, 'prices')) {
        return this.$cart.cart.prices.subtotal_including_tax.value;
      } else {
        return 0;
      }
    }
  },
  methods: {
    toggleRemove: function() {
      this.confirmRemove = !this.confirmRemove;
    },
    removeAll: function() {
      let self = this;
      let items = this.$cart.cart.items.map(function(i) {
        return {
          cart_item_uid: i.uid,
          quantity: 0
        }
      });

      this.$apollo.mutate({
        mutation: UPDATE_CART_ITEM,
        variables: {
          cartId: localStorage.cart,
          items: items
        }
      }).then(function(data) {
        self.$cart.cart = data.data.updateCartItems.cart;
      });
    }
  }
}
</script>