<template>
  <div :class="cssClass" :id="id" data-off-canvas>
    <button class="close-button" aria-label="Close menu" type="button" data-close v-if="closeButton">
      <span aria-hidden="true">&times;</span>
    </button>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'OffCanvas',
  props: {
    id: {
      type: String,
      default: "offCanvas"
    },
    position: {
      type: String,
      default: "left"
    },
    closeButton: {
      type: Boolean,
      default: true
    },
    inCanvas: {
      type: String,
      default: ""
    }
  },
  computed: {
    cssClass: function() {
      let cssClass = "off-canvas position-" + this.position;

      if (this.inCanvas !== "") {
        cssClass += " in-canvas-for-" + this.inCanvas;
      }

      return cssClass;
    }
  }
}
</script>