const fetch = require('cross-fetch');
// const fs = require('browserify-fs');
const possibleTypes = {};

fetch(`https://app.drayk.co.uk/graphql`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
        variables: {},
        query: `
      {
        __schema {
          types {
            kind
            name
            possibleTypes {
              name
            }
          }
        }
      }
    `,
    }),
}).then(result => result.json())
    .then(result => {
        result.data.__schema.types.forEach(supertype => {
            if (supertype.possibleTypes) {
                possibleTypes[supertype.name] =
                    supertype.possibleTypes.map(subtype => subtype.name);
            }
        });
    });

export { possibleTypes };