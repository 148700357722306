<template>
  <div>
    <p class="h5">
      <router-link :to="item.product.url_key + item.product.url_suffix" data-close>
        {{ item.product.name }}
      </router-link>
    </p>
    <p>
      {{ item.product.sku }}
    </p>
    <div v-if="item.configurable_options">
      <div v-for="option in item.configurable_options" :key="option.configurable_product_option_uid">
        <span>{{ option.option_label }}: </span>
        <span>{{ option.value_label }}</span>
      </div>
    </div>
    <price :price-range="item.product.price_range" :special-price="item.product.special_price" />
    <label>
      Qty
      <input type="number" ref="qty" v-model="qty" @keydown="updateButton = true" />
    </label>
    <button class="button" @click="update" v-if="updateButton">
      Update
    </button>
    <button class="button" @click="remove">
      Remove
    </button>
    <hr />
  </div>
</template>

<script>
import Price from "@/components/catalog/product/Price";
import {REMOVE_ITEM_FROM_CART, UPDATE_CART_ITEM} from "@/constants/graphql/checkout/cart";

export default {
  name: 'Item',
  components: {Price},
  props: ['item'],
  data() {
    return {
      updateButton: false,
      newQty: null
    }
  },
  computed: {
    qty: {
      get: function() {
        return this.item.quantity
      },
      set: function(val) {
        this.newQty = val;
      }
    }
  },
  methods: {
    update() {
      let self = this;

      this.$apollo.mutate({
        mutation: UPDATE_CART_ITEM,
        variables: {
          cartId: localStorage.cart,
          items: [{
            cart_item_uid: this.item.uid,
            quantity: this.newQty
          }]
        }
      }).then(function(data) {
        self.updateButton = false;
        self.$cart.cart = data.data.updateCartItems.cart;
      });
    },
    remove() {
      let self = this;

      this.$apollo.mutate({
        mutation: REMOVE_ITEM_FROM_CART,
        variables: {
          cartId: localStorage.cart,
          uid: this.item.uid
        }
      }).then(function(data) {
        self.$cart.cart = data.data.removeItemFromCart.cart;
      });
    }
  }
}
</script>