export class PriceModel {
    priceRange;
    specialPrice;

    constructor(priceRange, specialPrice = null) {
        this.priceRange = priceRange;
        this.specialPrice = specialPrice;
    }

    getPrice(){
        if(this.specialPrice !== null){
            return {min: this.specialPrice};
        }

        return this.getRegularPrice();
    }

    getRegularPrice(){
        if (this.priceRange.minimum_price.regular_price.value ===
            this.priceRange.maximum_price.regular_price.value){
            return {
                min: this.priceRange.minimum_price.regular_price.value
            };
        }

        return {
            min: this.priceRange.minimum_price.regular_price.value,
            max: this.priceRange.maximum_price.regular_price.value
        };
    }
}