import gql from 'graphql-tag';

export const CART_FRAGMENT = gql`
fragment CartFragment on Cart {
    id
    total_quantity
    prices {
        subtotal_including_tax {
            currency
            value
        }
    }
    items {
        uid
        quantity
        prices {
            price {
                currency
                value
            }
        }
        product {
            name
            url_key
            url_suffix
            sku
            price_range {
                minimum_price {
                    regular_price {
                        currency
                        value
                    }
                }
                maximum_price {
                    regular_price {
                        currency
                        value
                    }
                }
            }
            special_price
        }
        ... on ConfigurableCartItem {
            configurable_options {
                configurable_product_option_uid
                option_label
                configurable_product_option_value_uid
                value_label
            }
        }
    }
}
`

export const CREATE_EMPTY_CART = gql`
mutation {
    createEmptyCart
}
`

export const CART = gql`
${CART_FRAGMENT}

query($cartId: String!) {
    cart(
        cart_id: $cartId
    ) {
        ... CartFragment
    }
}
`

export const ADD_PRODUCTS_TO_CART = gql`
${CART_FRAGMENT}

mutation($items: [CartItemInput!]!, $cartId: String!) {
    addProductsToCart(
        cartId: $cartId
        cartItems: $items
    ) {
        cart {
            ... CartFragment
        }
    }
}
`

export const REMOVE_ITEM_FROM_CART = gql`
${CART_FRAGMENT}

mutation($cartId: String!, $uid: ID!) {
    removeItemFromCart(
        input: {
            cart_id: $cartId,
            cart_item_uid: $uid
        }
    ) {
        cart {
            ... CartFragment
        }
    }
}
`

export const UPDATE_CART_ITEM = gql`
${CART_FRAGMENT}

mutation($cartId: String!, $items: [CartItemUpdateInput!]!) {
    updateCartItems(
        input: {
            cart_id: $cartId,
            cart_items: $items
        }
    ) {
        cart {
            ... CartFragment
        }
    }
}
`

export const CUSTOMER_CART = gql`
${CART_FRAGMENT}

query {
    customerCart {
        ... CartFragment
    }
}
`

export const MERGE_CARTS = gql`
${CART_FRAGMENT}

mutation ($guest: String!, $customer: String!) {
    mergeCarts(
        source_cart_id: $guest,
        destination_cart_id: $customer
    ) {
        ... CartFragment
    }
}
`