<template>
  <div>
    <div v-if="total_quantity === 0">
      You have no items in your basket.
    </div>
    <div v-if="total_quantity > 0">
      <Summary />
      <item v-for="item in cart.items" :key="item.uid" :item="item" />
    </div>
  </div>
</template>

<script>
import Item from "@/components/checkout/cart/Item";
import Summary from "@/components/checkout/cart/Summary";

export default {
  name: 'Cart',
  components: {Item, Summary},
  computed: {
    cart: function() {
      return this.$cart.cart;
    },
    total_quantity: function() {
      return this.$cart.cart.total_quantity;
    }
  }
}
</script>