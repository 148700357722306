<template>
  <li :key="item.id" v-if="item.include_in_menu">
<!--    <router-link :to="categoryUrl(item)" data-close>-->
      <a href="#">{{ item.name }}</a>
<!--    </router-link>-->
    <ul v-if="item.children" class="vertical menu nested" data-drilldown>
      <SubMenuItems :key="child.id" v-for="child in item.children" :item="child"></SubMenuItems>
    </ul>
  </li>
</template>
<script>

import $ from "jquery";

export default {
  props: [ 'item' ],
  name: 'SubMenuItems',
  methods: {
    categoryUrl(category) {
      return "/" + category.url_path + category.url_suffix;
    }
  },
  mounted() {
    setTimeout(function() {
      $(document).foundation();
    }, 1000);
  },
}
</script>
