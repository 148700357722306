<template>
  <nav class="main-menu">
    <div class="grid-container show-for-large">
      <div class="grid-x text-center">
        <div class="cell auto"
           v-for="category in categoryList"
           :key="category.id">
          <router-link
              :to="categoryUrl(category)"
              v-if="category.include_in_menu">
            {{ category.name }}
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss">
@import "@/scss/settings";

.main-menu {
  background-color: $primary-color;

  a {
    display: block;
    color: $white;

    @each $breakpoint, $padding in $grid-container-padding {
      @include breakpoint($breakpoint) {
        padding: rem-calc($padding/2) 0;
      }
    }

    &:hover, &:focus {
      color: $medium-gray;
    }
  }
}
</style>

<script>
import { CATEGORY_LIST } from "@/constants/graphql/catalog/category"

export default {
  name: 'Navigation',
  props: ['rootCategoryUid'],
  data() {
    return {
      categoryList: []
    }
  },
  methods: {
    categoryUrl(category) {
      return "/" + category.url_path + category.url_suffix;
    }
  },
  apollo: {
    categoryList: {
      query: CATEGORY_LIST,
      variables() {
        return {
          uid: this.rootCategoryUid
        }
      }
    }
  }
}
</script>
