import gql from "graphql-tag";

export const CATEGORY_LIST = gql `
fragment CategoryChildren on CategoryTree {
    id
    name
    url_path
    url_suffix
    include_in_menu
}

query categoryList($uid: String!) {
    categoryList (
        filters: {
            parent_category_uid: {eq: $uid}
        }
    ) {
        ...CategoryChildren
        children {
            ...CategoryChildren
            children {
                ...CategoryChildren
                children {
                    ...CategoryChildren
                    children {
                        ...CategoryChildren
                        children {
                            ...CategoryChildren
                            children {
                                ...CategoryChildren
                                children {
                                    ...CategoryChildren
                                    children {
                                        ...CategoryChildren
                                        children {
                                            ...CategoryChildren
                                            children {
                                                ...CategoryChildren
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`

export const CATEGORY_VIEW = gql`
query categoryView ($uid: String!) {
    categoryView: categories (
        filters: {
            category_uid: {eq: $uid}
        }
    ) {
        items {
            name
        }
    }
}
`
