<template>
  <div>
    <span>{{ price.min | toCurrency }}</span>
    <span v-if="price.max">&nbsp;&hyphen;&nbsp;{{ price.max | toCurrency }}</span>
  </div>
</template>

<script>
export default {
  name: 'Range',
  props: ['price']
}
</script>