import Vue from 'vue'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import router from './router'
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client/core'
import { onError } from 'apollo-link-error';
import { ApolloLink, concat } from 'apollo-link';
import VueApollo from 'vue-apollo'

import $ from 'jquery'
// eslint-disable-next-line no-unused-vars
import Foundation from 'foundation-sites'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import { possibleTypes } from "@/Models/graphqlSchema";

Vue.config.productionTip = false
Vue.use(VueApollo)
Vue.use(VueCookies)

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      Authorization: "Bearer " + Vue.$cookies.get('customer') || null,
    }
  });
  return forward(operation);
})

const link = ApolloLink.from([
  onError(({ response, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(function({message, locations, path}) {
        console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        )
      });
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
    response.errors = null;
  }),
  new HttpLink({
    uri: 'https://app.drayk.co.uk/graphql',
    credentials: 'same-origin'
  })
]);

const client = new ApolloClient({
  link: concat(authMiddleware, link),
  cache: new InMemoryCache({
    possibleTypes,
    typePolicies: {
      Cart: {
        fields: {
          items: {
            merge(existing, incoming) {
              return incoming;
            }
          }
        }
      },
      Query: {
        fields: {
          storeConfig: {
            merge(existing = [], incoming = []) {
              return { ...existing, ...incoming };
            }
          }
        }
      }
    }
  })
});

const apolloProvider = new VueApollo({
  defaultClient: client
});

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.prototype.$cart = Vue.observable({
  cart: {
    items: []
  }
});

Vue.prototype.$customer = Vue.observable({
  customer: {}
});

new Vue({
  router,
  apolloProvider,
  render: h => h(App),
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route (to, from){
      setTimeout(function(){
        $(document).foundation();
      }, 0);
    }
  }
}).$mount('#app')
