<template>
  <div class="grid-container">
    <div class="grid-x align-middle grid-padding-y">
      <div class="cell small-4 hide-for-large">
        <i class="fas fa-bars fa-2x" aria-hidden="true" data-toggle="offCanvasMenu"></i>
      </div>
      <div class="cell small-4 large-6">
        <router-link to="/">
          <img src="http://via.placeholder.com/250x50" alt="logo">
        </router-link>
      </div>
      <div class="cell small-4 large-6">
        <div class="grid-x" style="
          align-items: center;
          justify-content: flex-end;
        ">
          <div class="cell shrink show-for-large">
            <router-link to="/customer/account/login" v-if="isGuest">
              Login
            </router-link>
            <router-link to="/customer/account" v-if="!isGuest">
              My Account
            </router-link>
          </div>
          <div class="cell shrink show-for-large">
            &nbsp;
            |
            &nbsp;
          </div>
          <div class="cell shrink show-for-large">
            <router-link to="/customer/account/create" v-if="isGuest">
              Register
              &nbsp;
            </router-link>
            <router-link to="/customer/account/logout" v-if="!isGuest">
              Sign Out
              &nbsp;
            </router-link>
          </div>
          <div class="cell shrink basket" data-toggle="offCanvasBasket">
            <i class="fa fa-shopping-basket fa-2x" aria-hidden="true"></i>
            <span class="badge" v-if="this.$cart.cart.total_quantity > 0">
              {{ this.$cart.cart.total_quantity }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/scss/settings";

.cell {
  &.basket {
    position: relative;

    .badge {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(25%);
    }
  }
}
</style>

<script>
export default {
  name: 'AppHeader',
  computed: {
    isGuest: function() {
      return !(Object.keys(this.$customer.customer).length > 0)
    }
  }
}
</script>