<template>
  <div>
    <range :price="price" />
    <range v-if="specialPrice" :price="regularPrice" />
  </div>
</template>

<script>
import Range from "@/components/catalog/product/price/Range";
import { PriceModel } from "@/Models/catalog/product/PriceModel";

export default {
  name: 'Price',
  props: ['priceRange', 'specialPrice'],
  data(){
    return {
      priceModel: new PriceModel(this.priceRange, this.specialPrice)
    }
  },
  components: {Range},
  computed: {
    price: function(){
      return this.priceModel.getPrice();
    },
    regularPrice: function(){
      return this.priceModel.getRegularPrice();
    }
  }
}
</script>