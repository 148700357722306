<template>
  <nav>
    <ul class="vertical menu drilldown" data-drilldown>
      <template v-for="category in categoryList">
        <SubMenuItems :key="category.id" :item="category"></SubMenuItems>
      </template>

      <template v-if="isGuest">
        <li>
          <router-link to="/customer/account/login" data-close>
            Login
          </router-link>
        </li>
        <li>
          <router-link to="/customer/account/create" data-close>
            Register
          </router-link>
        </li>
      </template>

      <template v-if="!isGuest">
        <li>
          <router-link to="/customer/account" data-close>
            My Account
          </router-link>
        </li>
        <li>
          <router-link to="/customer/account/logout" data-close>
            Sign Out
          </router-link>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
import { CATEGORY_LIST } from "@/constants/graphql/catalog/category"
import SubMenuItems from "@/components/layout/navigation/SubMenu";
import $ from "jquery";

export default {
  name: 'MobileNavigation',
  components: {SubMenuItems},
  props: ['rootCategoryUid'],
  data() {
    return {
      categoryList: []
    }
  },
  computed: {
    isGuest: function() {
      return !(Object.keys(this.$customer.customer).length > 0)
    }
  },
  methods: {
    categoryUrl(category) {
      return "/" + category.url_path + category.url_suffix;
    }
  },
  mounted() {
    setTimeout(function() {
      $(document).foundation();
    }, 1000);
  },
  apollo: {
    categoryList: {
      query: CATEGORY_LIST,
      variables() {
        return {
          uid: this.rootCategoryUid
        }
      }
    }
  }
}
</script>
