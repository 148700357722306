<template>
  <div id="app">
    <div class="off-canvas-wrapper">
      <off-canvas id="offCanvasBasket" position="right">
        <cart />
      </off-canvas>
      <off-canvas id="offCanvasMenu" position="left">
        <mobile-navigation v-if="rootCategoryUid" :root-category-uid="rootCategoryUid"></mobile-navigation>
      </off-canvas>
      <div class="off-canvas-content" data-off-canvas-content>
        <app-header></app-header>
        <navigation v-if="rootCategoryUid" :root-category-uid="rootCategoryUid"></navigation>
        <router-view :key="$route.fullPath"/>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/scss/default.scss";
</style>

<script>
import $ from "jquery";
import { ROOT_CATEGORY_UID } from "@/constants/graphql/storeConfig";
import { CREATE_EMPTY_CART, CART } from "@/constants/graphql/checkout/cart";

import Navigation from "@/components/layout/Navigation";
import MobileNavigation from "@/components/layout/MobileNavigation";
import AppHeader from "@/components/layout/AppHeader"
import OffCanvas from "@/components/foundation/OffCanvas"
import Cart from "@/components/checkout/Cart";
import {CUSTOMER} from "@/constants/graphql/customer";

export default {
  components: {
    Cart,
    Navigation,
    MobileNavigation,
    AppHeader,
    OffCanvas
  },
  data(){
    return {
      rootCategoryUid: null
    }
  },
  apollo: {
    rootCategoryUid: {
      query: ROOT_CATEGORY_UID,
      update: data => data.rootCategoryUid.root_category_uid
    }
  },
  mounted() {
    $(document).foundation();
    var self = this;

    if (!localStorage.cart) {
      this.$apollo.mutate({
        mutation: CREATE_EMPTY_CART
      }).then(function(data) {
        localStorage.cart = data.data.createEmptyCart;
      });
    }

    this.$apollo.query({
      query: CART,
      variables: {
        cartId: localStorage.cart
      }
    }).then(function(data) {
      self.$cart.cart = data.data.cart;
    });

    if (this.$cookies.get('customer')) {
      this.$apollo.query({
        query: CUSTOMER
      }).then(function(data) {
        self.$customer.customer = data.data.customer;
      });
    }
  }
}
</script>